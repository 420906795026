#contactPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.contact-content {
    flex-grow: 1;
    flex-direction: column;
}

.contact-description a {
    text-decoration: none;
    color: black;
}

.contact-description a:hover {
    text-decoration: underline;
    color: var(--primary);
}

.contact-description a:visited {
    text-decoration: none;
}

.contact-title {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--primary);
}