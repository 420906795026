:root {
    --main-background: #faf9ee;
    --light: #faf9ee;
    --dark: #051c1b;
    --primary: #21BBB1;
}

img {
    user-select: none;
    pointer-events: none;
}

#mainContainer {
    background-color: var(--main-background);
    height: 100vh;
}

.section-title {
    height: 100px;
}

.section-title img {
    height: 50px;
}


.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    section {
        min-height: 100vh;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 600px) {

    section {
        max-height: 100vh;
        overflow: auto;
    }

}