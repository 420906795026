footer {
    -webkit-box-shadow: 0px -10px 39px -19px rgba(0, 0, 0, 0.20);
    -moz-box-shadow: 0px -10px 39px -19px rgba(0, 0, 0, 0.20);
    box-shadow: 0px -10px 39px -19px rgba(0, 0, 0, 0.20);
    font-size: 0.8em;
    font-weight: bold;
    color: var(--light);
    width: 100%;
    background-color: var(--dark);
    height: 50px;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    footer {
        padding-bottom: 50px;
    }
}