.song-card-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center
}

.song-card {
    display: flex;
    padding: 20px 15px;
}

.song-card-content {
    display: flex;
    flex-direction: column;
}

.song-card-year {
    width: 70px;
    height: 25px;
    background-color: var(--primary);
    color: var(--light);
}

.song-card-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 1em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .song-card {
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid var(--primary);
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 600px) {

    .song-card-thumbnail {
        width: 200px;
    }

    .song-card {
        flex-direction: row;
        gap: 10px;
    }
}