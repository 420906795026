.promo-card {
    width: calc(100% - 60px);
    height: 200px;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    -webkit-box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75) inset;
    -moz-box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75) inset;
    box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75) inset;
    overflow: hidden;
}

.promo-card-image {
    min-width: 180px;
    height: 180px;
    background-image: url('./../../assets/images/thumbs/carezco-de-ti.jpg');
    border-radius: 7px;
    background-size: contain;
    background-position: center;
    -webkit-box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 39px -19px rgba(0, 0, 0, 0.75);
}

.promo-card-content {
    padding: 20px;
    color: var(--primary);
}

.promo-card-song-title {
    font-size: 30px;
    font-weight: bold;
}

.promo-card-title {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: normal;
}