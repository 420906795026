#homePage {
    display: flex;
    align-items: center;
    justify-content: start;
}

.left-container {
    position: relative;
    height: 100vh;
    background-color: var(--primary);
}

.right-container {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

#homePage img {
    width: 80%;

}

.logo-container {
    width: 100%;
}

.tags-container {
    margin-top: 15px;
    display: flex;
    gap: 20px;
}

.tag {
    color: var(--light);
    position: relative;
    transition: all 0.3s ease-in-out;
}

.tag::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 30%;
    background-color: var(--dark);
    left: -7px;
    top: 10px;
    transition: all 0.2s ease-in-out;
}

.tag:hover {
    color: var(--dark);
}

.tag:hover::after {
    height: 0%;
}

#homePage nav {
    background-color: var(--primary);
    width: 100%;
    height: 50px;
    z-index: 99;
}


#homePage nav li {
    position: relative;
    display: inline;
    margin-right: 20px;
    padding: 6px 10px;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;
}

#homePage nav li::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 2px;
    width: 0%;
    background-color: var(--light);
    transition: all 0.2s ease-in-out;
}

#homePage nav li.active {
    border: 2px solid var(--light);
}

#homePage nav li:hover::after {
    width: 25%;
}

#homePage nav li a {
    text-decoration: none;
    color: var(--light);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #homePage {
        flex-direction: column;
    }

    .left-container {
        width: 100%;
        height: 100vh;
    }

    .right-container {
        width: 100%;
        height: auto;
    }

    #homePage nav {
        position: fixed;
        bottom: 0px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 600px) {

    #homePage {
        height: 100vh;
        flex-direction: row;
    }

    .left-container {
        width: 55%;
        -webkit-box-shadow: 10px 0px 39px -19px rgba(0, 0, 0, 0.55);
        -moz-box-shadow: 10px 0px 39px -19px rgba(0, 0, 0, 0.55);
        box-shadow: 10px 0px 39px -19px rgba(0, 0, 0, 0.55);
    }

    .right-container {
        width: 45%;
    }

    #homePage nav {
        position: absolute;
        bottom: 0px;
    }

}